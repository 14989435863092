import { useImperativeHandle } from 'react';

import { EInputFieldTypes } from 'components/input-fields/types/enums';
import { TApiDrivenSelectFieldProps } from 'components/input-fields/types/field-props';

import useApiDrivenSelect from './useApiDrivenSelect';
import SelectField from '../select';

const ApiDrivenSelect = (props: TApiDrivenSelectFieldProps) => {
	const { api, formItemProps, fieldProps, imperativeRef } = props;

	const { queryFetchOption, optionsRef, filteredOptions, data, handleOnSearch, handleClearSearch } = useApiDrivenSelect({ api });

	useImperativeHandle(imperativeRef, () => ({
		options: optionsRef.current,
		fetchOptions: queryFetchOption.refetch
	}));

	return (
		<SelectField
			fieldType={EInputFieldTypes.SELECT}
			formItemProps={formItemProps}
			fieldProps={{
				...fieldProps,
				loading: queryFetchOption.isLoading,
				onSearch: handleOnSearch,
				onClear: handleClearSearch,
				showSearch: true,
				options: filteredOptions,
				onBlur: handleClearSearch, // clear search query, to set all options again
				onChange: async (value) => {
					handleClearSearch(); // clear search query, to set all options again

					if (fieldProps?.onChange) {
						const selectedOptionFromData = data.find((item) => {
							return api.responseDataStructure === 'tags' ? value === item : item[api.valueKey] === value;
						});

						fieldProps?.onChange?.(value, selectedOptionFromData);
					}
				},

				maxTagCount: 'responsive',
				filterOption: false // !important: Don't remove this, else search query options will be not visible
			}}
		/>
	);
};

export default ApiDrivenSelect;

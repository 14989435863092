import { ComponentType } from 'react';

import { Col, Row } from 'antd';

import ApiDrivenSelect from './components/api-driven-select';
// eslint-disable-next-line import/no-cycle
import CheckboxField from './components/checkbox';
import CustomField from './components/custom';
import TagField from './components/custom/tag';
import DatePickerField from './components/date/date-picker';
import DateRangePickerField from './components/date/date-range-picker';
import TimePickerField from './components/date/time-picker';
import TimeRangePickerField from './components/date/time-range-picker';
// eslint-disable-next-line import/no-cycle
import MediaField from './components/media';
import NumberField from './components/number';
import PasswordField from './components/password';
import PillsField from './components/pills';
import RadioGroupField from './components/radio-group';
import SelectField from './components/select';
import SwitchField from './components/switch';
import TextField from './components/text';
import TextareaField from './components/textarea';
import ImageFileField from './components/upload-file';
import { EInputFieldTypes } from './types/enums';
import { TFormInputFieldsProps } from './types/types';

type TFieldHash = {
	[key in EInputFieldTypes]: ComponentType<any> | null;
};

export const INPUT_TYPES_FIELD_HASH: TFieldHash = {
	[EInputFieldTypes.TEXT]: TextField,
	[EInputFieldTypes.NUMBER]: NumberField,
	[EInputFieldTypes.TEXTAREA]: TextareaField,
	[EInputFieldTypes.SELECT]: SelectField,
	[EInputFieldTypes.API_DRIVEN_SELECT]: ApiDrivenSelect,
	[EInputFieldTypes.SELECT_MEDIA]: MediaField,
	[EInputFieldTypes.DATE_PICKER]: DatePickerField,
	[EInputFieldTypes.DATE_RANGE_PICKER]: DateRangePickerField,
	[EInputFieldTypes.TIME_PICKER]: TimePickerField,
	[EInputFieldTypes.TIME_RANGE_PICKER]: TimeRangePickerField,
	[EInputFieldTypes.SWITCH]: SwitchField,
	[EInputFieldTypes.CHECKBOX]: CheckboxField,
	[EInputFieldTypes.PILLS]: PillsField,
	[EInputFieldTypes.CUSTOM]: CustomField,
	[EInputFieldTypes.RADIO_GROUP]: RadioGroupField,
	[EInputFieldTypes.TAG]: TagField,
	[EInputFieldTypes.PASSWORD]: PasswordField,
	[EInputFieldTypes.IMAGE_FILE]: ImageFileField,
};

const InputFields = ({ formFields }: TFormInputFieldsProps) => {
	return (
		<Row gutter={16}>
			{Object.entries(formFields).map(([fieldName, field]) => {
				const { fieldType, colProps, formItemProps, ...restFieldProps } = field;

				if (!(fieldType in INPUT_TYPES_FIELD_HASH)) {
					throw new Error(`Invalid field type: ${fieldType}`);
				}

				const Field = INPUT_TYPES_FIELD_HASH[fieldType];
				if (!Field) return null;

				return (
					<Col key={fieldName} span={24} {...colProps}>
						<Field
							{...restFieldProps}
							formItemProps={{
								...formItemProps,
								name: formItemProps?.name || fieldName,
							}}
						/>
					</Col>
				);
			})}
		</Row>
	);
};

export default InputFields;

import { EStatus } from 'types/enums';

import { EUserRoles } from './types';

const ADMIN_SUPER_ADMIN_ROLES = [EUserRoles.SUPER_ADMIN, EUserRoles.ADMIN];
const ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT = [
	EUserRoles.SUPER_ADMIN,
	EUserRoles.ADMIN,
	EUserRoles.PUBLISHER,
	EUserRoles.REVIEWER,
	EUserRoles.CONTENT_CREATOR
];

const sidebarPermissions = {
	'users.show': [...ADMIN_SUPER_ADMIN_ROLES],
	'users.admin_show': [EUserRoles.SUPER_ADMIN, EUserRoles.ADMIN]
};

// page
const adminPermissions = {
	'admin.create': [EUserRoles.SUPER_ADMIN, EUserRoles.ADMIN],
	'admin.changePassword': [EUserRoles.SUPER_ADMIN, EUserRoles.ADMIN],
	'admin.deactivate': [EUserRoles.SUPER_ADMIN, EUserRoles.ADMIN],
	'admin.changeRole': [EUserRoles.SUPER_ADMIN, EUserRoles.ADMIN]
};

// page
const authorPermissions = {
	'author.create': [...ADMIN_SUPER_ADMIN_ROLES],
	'author.edit': [...ADMIN_SUPER_ADMIN_ROLES],
	'author.delete': [...ADMIN_SUPER_ADMIN_ROLES]
};

// module
const masterPermissions = {
	'master.create': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	'master.edit': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	'master.delete': [...ADMIN_SUPER_ADMIN_ROLES],
	'master.category_subcategory.create': [...ADMIN_SUPER_ADMIN_ROLES],
	'master.category_subcategory.edit': [...ADMIN_SUPER_ADMIN_ROLES],
	'master.subcategory.delete': [...ADMIN_SUPER_ADMIN_ROLES],
	'master.main_category.delete': [EUserRoles.SUPER_ADMIN]
};

// module
const mediaPermissions = {
	'media.create': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	'media.showActions': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	'media.edit': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	'media.delete': [...ADMIN_SUPER_ADMIN_ROLES]
};

// module
const politicalPulsePermissions = {
	'politicalPulse.create': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	'politicalPulse.edit': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	'politicalPulse.delete': [...ADMIN_SUPER_ADMIN_ROLES]
};

// module
const contentPermissions = {
	'content.create': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	[`content.${EStatus.REQUEST_REVIEW}`]: [
		...ADMIN_SUPER_ADMIN_ROLES,
		EUserRoles.PUBLISHER,
		EUserRoles.CONTENT_CREATOR,
		EUserRoles.REVIEWER
	],
	[`content.${EStatus.REJECTED}`]: [...ADMIN_SUPER_ADMIN_ROLES, EUserRoles.PUBLISHER, EUserRoles.REVIEWER],
	[`content.${EStatus.READY_FOR_PUBLICATION}`]: [...ADMIN_SUPER_ADMIN_ROLES, EUserRoles.PUBLISHER, EUserRoles.REVIEWER],

	[`content.${EStatus.PUBLISHED}`]: [...ADMIN_SUPER_ADMIN_ROLES, EUserRoles.PUBLISHER],
	[`content.${EStatus.ARCHIVED}`]: [...ADMIN_SUPER_ADMIN_ROLES, EUserRoles.PUBLISHER],

	'content.history': [...ADMIN_SUPER_ADMIN_ROLES, EUserRoles.PUBLISHER],
	'content.restore': [...ADMIN_SUPER_ADMIN_ROLES, EUserRoles.PUBLISHER],
	'content.delete': [...ADMIN_SUPER_ADMIN_ROLES, EUserRoles.PUBLISHER],
	'content.edit': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	'content.clone': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],

	'content.changeToInitialDraftStatus': [
		...ADMIN_SUPER_ADMIN_ROLES,
		EUserRoles.PUBLISHER,
		EUserRoles.CONTENT_CREATOR,
		EUserRoles.REVIEWER
	],
	'content.showUpdateStatusOption': [
		...ADMIN_SUPER_ADMIN_ROLES,
		EUserRoles.PUBLISHER,
		EUserRoles.CONTENT_CREATOR,
		EUserRoles.REVIEWER
	],
	'content.publish-article': [...ADMIN_SUPER_ADMIN_ROLES, EUserRoles.PUBLISHER],
	'content.edit-published-content': [...ADMIN_SUPER_ADMIN_ROLES, EUserRoles.PUBLISHER] // for now used for articles only
};

const pollPermission = {
	'poll.edit': [...ADMIN_SUPER_ADMIN_ROLES, EUserRoles.PUBLISHER]
};

const communitySpacePermissions = {
	'community_space.participant.block': [...ADMIN_SUPER_ADMIN_ROLES],
	'communityRoom.create': [...ADMIN_SUPER_ADMIN_ROLES],
	'communityRoom.edit': [...ADMIN_SUPER_ADMIN_ROLES],
	'communityRoom.delete': [...ADMIN_SUPER_ADMIN_ROLES],
	'communityRoom.request.accept': [...ADMIN_SUPER_ADMIN_ROLES],
	'communityRoom.request.reject': [...ADMIN_SUPER_ADMIN_ROLES]
};

const rafflesPermissions = {
	'raffles.create': [...ADMIN_SUPER_ADMIN_ROLES],
	'raffles.edit': [...ADMIN_SUPER_ADMIN_ROLES],
	'raffles.delete': [...ADMIN_SUPER_ADMIN_ROLES],
	'raffle.editAfterStarted': [EUserRoles.SUPER_ADMIN]
};

const notificationPermissions = {
	'notification.create': [...ADMIN_SUPER_ADMIN_ROLES],
	'notification.edit': [...ADMIN_SUPER_ADMIN_ROLES],
	'notification.delete': [...ADMIN_SUPER_ADMIN_ROLES]
};

const subscriptionPermissions = {
	'subscription.tier.create': [...ADMIN_SUPER_ADMIN_ROLES],
	'subscription.tier.edit': [...ADMIN_SUPER_ADMIN_ROLES],
	'subscription.tier.delete': [...ADMIN_SUPER_ADMIN_ROLES],
	'subscription.tier.changeStatus': [...ADMIN_SUPER_ADMIN_ROLES],
	'subscription.tier.updatePrice': [...ADMIN_SUPER_ADMIN_ROLES],

	'subscription.add-on.create': [...ADMIN_SUPER_ADMIN_ROLES],
	'subscription.add-on.edit': [...ADMIN_SUPER_ADMIN_ROLES],
	'subscription.add-on.delete': [...ADMIN_SUPER_ADMIN_ROLES],
	'subscription.add-on.changeStatus': [...ADMIN_SUPER_ADMIN_ROLES],
	'subscription.add-on.updatePrice': [...ADMIN_SUPER_ADMIN_ROLES]
};

const userPermissions = {
	'users.delete': [...ADMIN_SUPER_ADMIN_ROLES],
	'super-admin.delete': [EUserRoles.SUPER_ADMIN],
	'users.edit': [...ADMIN_SUPER_ADMIN_ROLES]
};

const propagandaPermissions = {
	'propaganda.create': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	'propaganda.edit': [...ALL_MANAGERS_EXCEPT_VIEWER_AND_CLIENT],
	'propaganda.delete': [...ADMIN_SUPER_ADMIN_ROLES]
};

export const UserRolePermissions = {
	...sidebarPermissions,
	...adminPermissions,
	...masterPermissions,
	...authorPermissions,
	...mediaPermissions,
	...politicalPulsePermissions,
	...contentPermissions,
	...communitySpacePermissions,
	...rafflesPermissions,
	...notificationPermissions,
	...subscriptionPermissions,
	...userPermissions,
	...propagandaPermissions,
	...pollPermission
};

export type TUserRolePermissionsName = keyof typeof UserRolePermissions;

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum API_URLS {
	AUTH_REGISTER = 'auth/register',
	AUTH_LOGIN = 'auth/login',

	AUTH_FORGOT_PASSWORD = 'auth/forget-password',
	AUTH_CHANGE_PASSWORD = 'auth/change-password',
	AUTH_RESET_PASSWORD = 'auth/reset-password',
	AUTH_LOGOUT = 'auth/logout',
	AUTH_CREATE_CMS_USER = 'auth/create-user',
	AUTH_UPDATE_CMS_USER = 'auth/update-user-role',

	USER_UPDATE_PROFILE = 'user/update-profile', // By user itself
	USER_UPDATE_BY_ADMIN = 'user/update/user-details/{{id}}', // By admin

	PASSWORD_CHANGE_REQUEST_BY_ADMIN = 'user/change-pw', // password will be changed by admin
	MEDIA = 'media',
	MEDIA_IMAGE = 'media/image',
	MEDIA_IMAGE_TAGS = 'media/image/tags',
	MEDIA_UPLOAD_IMAGE = 'storage/upload-image',
	MEDIA_UPLOAD_BULK_IMAGES = 'storage/bulk/upload-image',
	MEDIA_AUDIO = 'media/audio',
	MEDIA_UPLOAD_SINGLE_AUDIO = 'storage/upload-audio',
	MEDIA_UPLOAD_BULK_AUDIO = 'storage/bulk/upload-audio',
	MEDIA_UPLOAD_VIDEO = 'storage/upload-video',
	MEDIA_VIDEO_TAGS = 'media/video/tags',
	RE_TRANSCODE_VIDEO = 'media/video/retry-transcode',
	ARTICLE_TAGS = 'article/tags',

	MEDIA_VIDEO_REORDER_TRANSCODING = 'media/video/update/order',
	MEDIA_VIDEO = 'media/video',
	VIDEO_TAGS = 'media/video/tags',
	MEDIA_AUDIO_TAGS = 'media/audio/tags',
	CATEGORY = 'category',
	ALL_CATEGORIES = 'category/all',
	CATEGORY_ID_SUB_CATEGORIES = 'category/{{categoryId}}/sub-categories',
	CATEGORY_ID_WITH_SUB_CATEGORIES = 'category/{{categoryId}}/with-sub-categories',
	AUTHOR = 'author',
	AUTHOR_ALL = 'author/all',

	MASTER_DISTRICTS = 'master/districts',
	MASTER_DISTRICT = 'master/district',
	MASTER_LANGUAGES = 'master/languages',
	MASTER_CITIES_BY_COUNTRY_ID = 'master/cities/country',
	MASTER_COUNTRIES = 'master/countries',

	STREAM = 'stream',
	STREAM_LIVE_STATUS = 'stream/live-status',
	MASTER_STATES = 'master/states',
	USER = 'user',
	GET_ALL_CMS_USER = 'user/get/all/cms-user-profile',
	LOGGED_IN_USER_PROFILE = 'user/me/profile', // Logged in user
	PROFILE_USER_STATUS = 'user/status', // Logged in user

	USER_SUBSCRIPTION = 'user-subscription',

	ARTICLE = 'article',
	ARTICLE_BY_SLUG = 'article/by',
	ARTICLE_BY_ID = 'article/by',
	ARTICLE_VERSIONS = 'article/{{id}}/versions',
	ARTICLE_RESTORE_VERSION = 'article/version/{{id}}/restore',
	ARTICLE_BY_VERSION_ID = 'article/version/{{id}}',
	UPDATE_ARTICLE_STATUS = 'article/status-change',
	FEATURED_ARTICLE = 'article/featured',

	PARTY = 'party',
	PARTY_BY_ID = 'party/by',
	PARTY_ALL = 'party/all',

	LEADER = 'leader',
	LEADER_BY_ID = 'leader/by',
	LEADER_ALL = 'leader/all',

	MOVIES = 'movies',
	MOVIES_TAGS = 'movies/tags',
	MOVIES_STATUS_CHANGE = 'movies/status-change',
	MOVIES_VERSIONS = 'movie/{{id}}/versions',
	MOVIES_RESTORE_VERSION = 'movie/version/{{id}}/restore',
	MOVIES_BY_VERSION_ID = 'movie/version/{{id}}',

	PODCAST = 'podcast',
	PODCAST_TAGS = 'podcast/tags',
	PODCAST_EPISODE = 'podcast/episode',
	PODCAST_SEASON = 'podcast/season',
	PODCAST_SEASON_EPISODES = 'podcast/all/episodes',
	PODCAST_STATUS_CHANGE = 'podcast/status-change/podcast',
	PODCAST__EPISODE_STATUS_UPDATE = 'podcast/status-change/episode',
	PODCAST_VERSIONS = 'podcast/{{id}}/versions',
	PODCAST_RESTORE_VERSION = 'podcast/version/{{id}}/restore',
	PODCAST_BY_VERSION_ID = 'podcast/version/{{id}}',

	SERIES = 'series',
	SERIES_TAGS = 'series/tags',
	SERIES_STATUS_CHANGE = 'series/status-change',
	SERIES_SEASON_EPISODE_STATUS_CHANGE = 'series/seasons/episodes/status-change',
	SERIES_SERIES_ID_SEASONS = 'series/{{seriesId}}/seasons',
	SERIES_EPISODE = 'series/seasons/episodes',
	SERIES_SEASONS = 'series/seasons',
	SERIES_VERSIONS = 'series/{{id}}/versions',
	SERIES_RESTORE_VERSION = 'series/version/{{id}}/restore',
	SERIES_BY_VERSIONS_ID = 'series/version/{{id}}',

	CREDIT = 'credit',

	DYNAMIC_PAGE = 'dynamic-page',
	DYNAMIC_PAGE_STATUS_CHANGE = 'dynamic-page/status-change',
	DYNAMIC_PAGE_SECTION = 'dynamic-page/section',
	DYNAMIC_PAGE_SECTION_STATUS_CHANGE = 'dynamic-page/section/status-change',
	DYNAMIC_PAGE_ID_SECTION = 'dynamic-page/{{pageId}}/section',
	DYNAMIC_PAGE_ID_SECTION_ALL = 'dynamic-page/{{pageId}}/section/all',
	DYNAMIC_PAGE_ID_SECTION_REORDER = 'dynamic-page/{{pageId}}/section/re-order',

	DYNAMIC_PAGE_SECTION_ID_CONTENT = 'dynamic-page/section/{{sectionId}}/content',
	DYNAMIC_PAGE_SECTION_ID_CONTENT_REORDER = 'dynamic-page/section/{{sectionId}}/content/re-order',
	DYNAMIC_PAGE_SECTION_CONTENT = 'dynamic-page/section/content',

	COLLECTION = 'collection',
	COLLECTION_ID_CONTENT = 'collection/{{collectionId}}/content',

	POLL_QUESTION = 'poll/question',
	POLL_QUESTION_OPTION = 'poll/question/option',
	POLL_QUESTION_ACTIVE_STATUS_CHANGE = 'poll/question/{{id}}/status',
	POLL = 'poll',
	POLL_STATUS_UPDATE = 'poll/{{id}}/status', // activate/deactivate
	POLL_TAGS = 'poll/tags',
	POLL_PARTICIPANTS = 'poll/participants',
	POLL_STATISTICS_BY_CATEGORIES = 'poll/polls/category-overview',
	POLL_PARTICIPANTS_BY_DISTRICT = 'poll/participants/polls/district-monthly-participation',
	POLL_OVERVIEW_TOTAL = 'poll/participants/polls/statistics-and-comparison',
	POLL_TRENDING = 'poll/participants/polls/trending',
	POLL_RESULT = 'poll/{{pollId}}/statistics',
	POLL_QUESTION_RESULT = 'poll/question/{{questionId}}/statistics',

	COMMUNITY_ROOM = 'room',
	COMMUNITY_ROOM_PARTICIPANT = 'room-member',
	COMMUNITY_ROOM_PARTICIPANT_MODE_CHANGE = 'room-member/change-mode',

	COMMUNITY_ROOM_REQUEST = 'room/request',
	COMMUNITY_ROOM_REQUEST_ID_ACCEPT = 'room/request/{{requestId}}/accept',
	COMMUNITY_ROOM_REQUEST_ID_REJECT = 'room/request/{{requestId}}/reject',

	RAFFLES = 'raffles',
	VALIDATE_RAFFLE_SLUG = 'raffles/validate/slug',

	NOTIFICATION = 'notification',

	SUBSCRIPTION_TIERS = 'subscription/tiers',
	SUBSCRIPTION_TIERS_STATUS_CHANGE = 'subscription/tiers/status-change',
	SUBSCRIPTION_TIERS_UPDATE_PRICE = 'subscription/tiers/update-price',

	SUBSCRIPTION_ADD_ON = 'subscription/add-on',
	SUBSCRIPTION_ADD_ON_STATUS_CHANGE = 'subscription/add-on/status-change',
	SUBSCRIPTION_ADD_ON_UPDATE_PRICE = 'subscription/add-on/update-price',
	SUBSCRIPTION_PAID_USERS = 'user-subscription',

	PROPAGANDA = 'propaganda',

	DASHBOARD_USER_ENGAGEMENT = 'admin-dashboard/user-engagement-stats',
	DASHBOARD_PUBLIC_CONTENT = 'admin-dashboard/public-content-summary',
	DASHBOARD_SUBSCRIBERS_GROUPED_BY_STATES = 'admin-dashboard/subscribe-users-grouped-by-state',

	BUG_REPORT_SUBMIT = 'bug-reporting/submit-request',

	EDUCATION_GRADE = 'grade',
	EDUCATION_SUBJECT = 'subject',
	EDUCATION_COURSE = 'course',
	EDUCATION_COURSE_TYPES = 'category/all/education-sub-categories', // course types are sub categories of the main education category.
	EDUCATION_COURSE_CHANGE_STATUS = 'course/status-change/{{courseId}}',
	EDUCATION_COURSE_TAGS = 'course/tags',
	EDUCATION_CHAPTER = 'chapter',
	EDUCATION_CHAPTER_TAGS = 'chapter/tags',
	EDUCATION_LESSON = 'lesson',

	// ALLIANCE

	ALLIANCE = 'alliance',
	ALLIANCE_BY_ID = 'alliance/by',

	// Global search
	SEARCH = 'search'
}

/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';

// eslint-disable-next-line import/no-cycle
import DefaultLayout from 'components/layouts/DefaultLayout';
import DashboardPage from 'pages/dashboard';

const SaveDynamicPageMultiLang = lazy(() => import('pages/save-dynamic-page/SaveDynamicPageMultiLang'));

const CreatePartyPageMultiLang = lazy(() => import('pages/political-pulse/parties/CreatePartyPageMultiLang'));
const SaveSeriesSeasonPageMultiLang = lazy(
	() => import('pages/content/series-seasons/save-season/SaveSeriesSeasonPageMultiLang')
);
const SaveSeriesMultiLang = lazy(() => import('pages/content/save-series/SaveSeriesMultiLang'));
const SaveMovieMultiLang = lazy(() => import('pages/save-movie/SaveMovieMultiLang'));
const Collections = lazy(() => import('pages/collections'));
const CommunityRoomsPage = lazy(() => import('pages/community-rooms'));
const DynamicPagesPage = lazy(() => import('pages/dynamic-pages'));
const SubCategoriesPage = lazy(() => import('pages/masters/sub-categories-page'));
const NotificationsPage = lazy(() => import('pages/notifications'));
const NotificationLogsPage = lazy(() => import('pages/notifications/notification-logs'));
const PollsListPage = lazy(() => import('pages/polls/polls-list'));
const PollsDemographic = lazy(() => import('pages/polls/polls-results/PollsDemographic'));
const PropagandaPage = lazy(() => import('pages/propaganda'));
const SaveArticlePage = lazy(() => import('pages/save-article'));
const SaveArticlePageMultiLang = lazy(() => import('pages/save-article-multi-lang'));
const SaveCollectionPage = lazy(() => import('pages/save-collection-page'));
const SaveCommunityRoomPage = lazy(() => import('pages/save-community-room'));
const SaveDynamicPagePage = lazy(() => import('pages/save-dynamic-page'));
const CreatePollsPage = lazy(() => import('pages/save-poll'));
const SavePollQuestion = lazy(() => import('pages/save-poll/question-options-tab/save-question'));
const PollResult = lazy(() => import('pages/poll-result'));
const SubscriptionsPage = lazy(() => import('pages/subscriptions'));
const UpdateUserProfilePage = lazy(() => import('pages/update-user-profile'));
const VersionsPage = lazy(() => import('pages/versions'));

const ForgetPasswordPage = lazy(() => import('pages/auth/forget-password'));
const Login = lazy(() => import('pages/auth/login'));
const VerifyCodePage = lazy(() => import('pages/auth/verify-code'));
const AuthorsPage = lazy(() => import('pages/authors/authors'));
const CommunityRoomDetails = lazy(() => import('pages/community-room-details'));
const ArticlePage = lazy(() => import('pages/content/articles'));
const ViewArticlePage = lazy(() => import('pages/content/articles/articles-data-page/viewArticle'));
const MoviesPage = lazy(() => import('pages/content/movies'));
const EpisodesListPage = lazy(() => import('pages/content/podcast/episodes'));
const PodcastPage = lazy(() => import('pages/content/podcast/podcast/podcast-list/podcast'));
const PodcastSeasonsPage = lazy(() => import('pages/content/podcast/seasons/seasonList/SeasonsList'));
const SaveSeriesPage = lazy(() => import('pages/content/save-series'));
const SeriesPage = lazy(() => import('pages/content/series'));
const SeriesSeasonEpisodes = lazy(() => import('pages/content/series-season-episodes'));
const SeriesSeasonsPage = lazy(() => import('pages/content/series-seasons'));
const SaveSeriesSeasonPage = lazy(() => import('pages/content/series-seasons/save-season'));
const CreditsPage = lazy(() => import('pages/credits/credits'));
const CategoriesPage = lazy(() => import('pages/masters/category-page'));
const AlliancePage = lazy(() => import('pages/political-pulse/AlliancePage'));
const DistrictsPage = lazy(() => import('pages/masters/districts'));
const AudioPage = lazy(() => import('pages/media/audio/audio'));
const ImagePage = lazy(() => import('pages/media/images'));
const StreamPage = lazy(() => import('pages/media/stream/StreamListing'));
const VideoPage = lazy(() => import('pages/media/video'));
const CreateLeaderPage = lazy(() => import('pages/political-pulse/leaders/CreateLeaderPage'));
const CreateLeaderMultiLangPage = lazy(() => import('pages/political-pulse/leaders/CreateLeaderMultiLangPage'));

const LeadersPage = lazy(() => import('pages/political-pulse/leaders/CreateLeaderPage/LeadersPage'));
const CreatePartyPage = lazy(() => import('pages/political-pulse/parties/create-party/createParty'));
const PartiesPage = lazy(() => import('pages/political-pulse/parties'));
const PollDetails = lazy(() => import('pages/polls/polls-list/poll-data-page/PollDetails'));
const RafflesPage = lazy(() => import('pages/raffles'));
const RaffleDetailsPage = lazy(() => import('pages/raffles-details'));
const SaveMoviePage = lazy(() => import('pages/save-movie'));
const UsersPage = lazy(() => import('pages/users'));
const UserSubscriptionDetailsPage = lazy(() => import('pages/user-subscription-details'));

const ChangePasswordPage = lazy(() => import('pages/change-password'));

const EducationGradePage = lazy(() => import('pages/education-grade'));
const EducationSubjectsPage = lazy(() => import('pages/education-subject'));
const EducationChaptersPage = lazy(() => import('pages/education-chapter'));
const EducationCoursesPage = lazy(() => import('pages/education-course'));
const EducationCourseTypesPage = lazy(() => import('pages/education-course-types'));
const EducationLessonsPage = lazy(() => import('pages/education-lesson'));

export const AppRoutes = [
	{
		path: '/*',
		component: <DefaultLayout />,
		authGuard: true
	},
	{
		path: '/auth/login',
		component: <Login />,
		authGuard: false
	},
	{
		path: '/auth/forget-password',
		component: <ForgetPasswordPage />,
		authGuard: false
	},
	{
		path: '/auth/verify-code',
		component: <VerifyCodePage />,
		authGuard: false
	},
	{
		path: '/change-password',
		component: <ChangePasswordPage />,
		authGuard: true
	}
];

export const LayoutRouts = [
	{
		path: '/districts',
		component: <DistrictsPage />,
		authGuard: true
	},
	{
		path: '/',
		component: <DashboardPage />,
		authGuard: true
	},
	{
		path: '/users',
		component: <UsersPage />,
		authGuard: true
	},
	{
		path: '/paid-user/:paidUserId/details',
		component: <UserSubscriptionDetailsPage />,
		authGuard: true
	},
	{
		path: '/authors',
		component: <AuthorsPage />,
		authGuard: true
	},
	{
		path: '/images',
		component: <ImagePage />,
		authGuard: true
	},
	{
		path: '/audios',
		component: <AudioPage />,
		authGuard: true
	},
	{
		path: '/live-streams',
		component: <StreamPage />,
		authGuard: true
	},
	{
		path: '/videos',
		component: <VideoPage />,
		authGuard: true
	},
	{
		path: '/categories',
		component: <CategoriesPage />,
		authGuard: true
	},
	{
		path: '/alliance',
		component: <AlliancePage />,
		authGuard: true
	},
	{
		path: '/sub-categories/:categoryId',
		component: <SubCategoriesPage pageType='category' />,
		authGuard: true
	},
	{
		path: '/sub-categories/:categoryId/:parentSubCategoryId',
		component: <SubCategoriesPage pageType='sub-category' />,
		authGuard: true
	},
	{
		path: '/articles',
		component: <ArticlePage />,
		authGuard: true
	},
	{
		path: '/edit-article-multi-lang/:articleId/:language',
		component: <SaveArticlePageMultiLang />,
		authGuard: true
	},
	{
		path: '/clone-article-multi-lang/:articleId/:language',
		component: <SaveArticlePageMultiLang isClone />,
		authGuard: true
	},
	{
		path: '/edit-article/:articleId',
		component: <SaveArticlePage />,
		authGuard: true
	},
	{
		path: '/create-article',
		component: <SaveArticlePage />,
		authGuard: true
	},
	{
		path: '/clone-article/:articleId',
		component: <SaveArticlePage isClone />,
		authGuard: true
	},
	{
		path: '/leaders',
		component: <LeadersPage />,
		authGuard: true
	},
	{
		path: '/create-leader',
		component: <CreateLeaderPage />,
		authGuard: true
	},
	{
		path: '/edit-leader-multi-lang/:leaderId',
		component: <CreateLeaderMultiLangPage />,
		authGuard: true
	},
	{
		path: '/edit-leader/:leaderId',
		component: <CreateLeaderPage />,
		authGuard: true
	},
	{
		path: '/parties',
		component: <PartiesPage />,
		authGuard: true
	},
	{
		path: '/podcasts',
		component: <PodcastPage />,

		authGuard: true
	},
	{
		path: '/podcast/:podcastId/seasons',
		component: <PodcastSeasonsPage />,
		authGuard: true
	},
	{
		path: '/podcast/season/:seasonId',
		component: <EpisodesListPage />,
		authGuard: true
	},
	{ path: '/create-party', component: <CreatePartyPage />, authGuard: true },
	{
		path: '/edit-party-multi-lang/:partyId',
		component: <CreatePartyPageMultiLang />,
		authGuard: true
	},
	{
		path: '/edit-party/:partyId',
		component: <CreatePartyPage />,
		authGuard: true
	},
	{
		path: '/viewArticle/:id',
		component: <ViewArticlePage />,
		authGuard: true
	},
	{
		path: '/movies',
		component: <MoviesPage />,
		authGuard: true
	},
	{
		path: '/create-movie',
		component: <SaveMoviePage />,
		authGuard: true
	},
	{
		path: '/edit-movie/:movieId',
		component: <SaveMoviePage />,
		authGuard: true
	},
	{
		path: '/edit-movie-multi-lang/:movieId',
		component: <SaveMovieMultiLang />,
		authGuard: true
	},
	{
		path: '/series',
		component: <SeriesPage />,
		authGuard: true
	},
	{
		path: '/:contentType/versions/:contentId',
		component: <VersionsPage />,
		authGuard: true
	},
	{
		path: '/create-series',
		component: <SaveSeriesPage />,
		authGuard: true
	},
	{
		path: '/edit-series/:seriesId',
		component: <SaveSeriesPage />,
		authGuard: true
	},
	{
		path: '/multi-lang-series/:seriesId',
		component: <SaveSeriesMultiLang />,
		authGuard: true
	},
	{
		path: '/series/:seriesId/seasons',
		component: <SeriesSeasonsPage />,
		authGuard: true
	},
	{
		path: '/series/:seriesId/create-season',
		component: <SaveSeriesSeasonPage />,
		authGuard: true
	},
	{
		path: '/series/:seriesId/edit-season/:seasonId',
		component: <SaveSeriesSeasonPage />,
		authGuard: true
	},
	{
		path: '/series/:seriesId/edit-season-multi-lang/:seasonId',
		component: <SaveSeriesSeasonPageMultiLang />,
		authGuard: true
	},
	{
		path: '/series/seasons/:seasonId/episodes',
		component: <SeriesSeasonEpisodes />,
		authGuard: true
	},
	{
		path: '/credits',
		component: <CreditsPage />,
		authGuard: true
	},
	{ path: '/dynamic-pages', component: <DynamicPagesPage />, authGuard: true },
	{
		path: '/create-dynamic-page',
		component: <SaveDynamicPagePage />,
		authGuard: true
	},
	{
		path: '/edit-dynamic-page/:pageId',
		component: <SaveDynamicPagePage />,
		authGuard: true
	},
	{
		path: '/edit-dynamic-page-multi-lang/:pageId',
		component: <SaveDynamicPageMultiLang />,
		authGuard: true
	},
	{
		path: '/save-poll-question/:pollId',
		component: <SavePollQuestion />,
		authGuard: true
	},
	{
		path: '/poll/:pollId/edit-question/:questionId',
		component: <SavePollQuestion />,
		authGuard: true
	},
	{
		path: '/poll-result/:pollId',
		component: <PollResult />,
		authGuard: true
	},
	{ path: '/collections', component: <Collections />, authGuard: true },
	{
		path: '/edit-collection/:collectionId',
		component: <SaveCollectionPage />,
		authGuard: true
	},
	{
		path: '/polls/create-poll',
		component: <CreatePollsPage />,
		authGuard: true
	},
	{
		path: '/polls/edit-poll/:pollId',
		component: <CreatePollsPage />,
		authGuard: true
	},
	{
		path: '/polls',
		component: <PollsListPage />,
		authGuard: true
	},
	{
		path: '/polls-demographics',
		component: <PollsDemographic />,
		authGuard: true
	},
	{
		path: '/polls/view-poll/:pollId',
		component: <PollDetails />,
		authGuard: true
	},
	{
		path: '/community-rooms/:roomId/details',
		component: <CommunityRoomDetails />,
		authGuard: true
	},
	{ path: '/community-rooms', component: <CommunityRoomsPage />, authGuard: true },
	{
		path: '/create-community-room',
		component: <SaveCommunityRoomPage />,
		authGuard: true
	},
	{
		path: '/edit-community-room/:communityRoomId',
		component: <SaveCommunityRoomPage />,
		authGuard: true
	},
	{
		path: '/raffles',
		component: <RafflesPage />,
		authGuard: true
	},
	{ path: '/raffles/:raffleId/details', component: <RaffleDetailsPage />, authGuard: true },
	{ path: '/subscriptions', component: <SubscriptionsPage />, authGuard: true },
	{
		path: '/notifications',
		component: <NotificationsPage />,
		authGuard: true
	},
	{
		path: '/notification/:notificationId/logs',
		component: <NotificationLogsPage />,
		authGuard: true
	},
	{
		path: '/user-profile',
		component: <UpdateUserProfilePage />,
		authGuard: true
	},
	{ path: '/propaganda', component: <PropagandaPage />, authGuard: true },
	{
		path: '/education-grade',
		component: <EducationGradePage />,
		authGuard: true
	},
	{
		path: '/education-subjects',
		component: <EducationSubjectsPage />,
		authGuard: true
	},

	{
		path: '/education-courses',
		component: <EducationCoursesPage />,
		authGuard: true
	},
	{
		path: '/education-courses/:courseId/chapters',
		component: <EducationChaptersPage />,
		authGuard: true
	},
	{
		path: '/education-courses/:courseId/chapters/:chapterId/lessons',
		component: <EducationLessonsPage />,
		authGuard: true
	},
	{
		path: '/education-course-types',
		component: <EducationCourseTypesPage />,
		authGuard: true
	}
];

/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';

import { Button, Flex, List, Pagination, Result, Spin, Tag, Typography, Image } from 'antd';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

import { DEFAULT_ITEMS_LIMIT_PER_PAGE, IMAGE_FALLBACK_URL } from 'config/constants';
import { EGlobalSearchContentTypes, IGlobalSearchResponseListItem } from 'types/global-search';

import { useSearchContext } from '../provider/search-hook';

const GlobalSearchData = () => {
	const { querySearchData, searchApiParams, setSearchApiParams, setOpenSearchData } = useSearchContext();

	if (querySearchData?.isError) {
		return (
			<Result
				className='w-[1000px] h-[550px]'
				status='error'
				title='Error'
				subTitle={
					querySearchData?.error.message
						? querySearchData?.error.message
						: querySearchData?.error
							? String(querySearchData?.error)
							: 'Sorry, something went wrong. Please try again later.'
				}
			/>
		);
	}

	return (
		<Spin spinning={querySearchData?.isFetching}>
			<Flex vertical className='w-[1000px] h-[550px]'>
				<div className='overflow-y-scroll h-[500px] '>
					<List
						itemLayout='vertical'
						dataSource={querySearchData?.data?.items || []}
						renderItem={(item) => <RenderItem item={item} />}
					/>
				</div>

				<Flex justify='flex-end' align='end' className='h-[50px]'>
					{(querySearchData?.data?.items?.length || 0) > 0 && (
						<Pagination
							pageSize={searchApiParams.limit || DEFAULT_ITEMS_LIMIT_PER_PAGE}
							total={querySearchData.data?.totalItems || 0}
							current={searchApiParams.page}
							showSizeChanger={false}
							onChange={(page) => setSearchApiParams((pv) => ({ ...pv, page }))}
						/>
					)}
				</Flex>
			</Flex>
		</Spin>
	);
};

export default GlobalSearchData;

const RenderItem = ({ item }: { item: IGlobalSearchResponseListItem }) => {
	const { setOpenSearchData } = useSearchContext();

	const navigate = useNavigate();

	const { contentType } = item;

	const { title, description, tagColor, imageUrl } =
		getHashContentTypeToItemData(item)[contentType as EGlobalSearchContentTypes] || {};

	const handleClick = () => {
		const navigationDetails = getHashContentTypeToNavigatePath(item)[contentType as EGlobalSearchContentTypes];
		navigate(navigationDetails.to, navigationDetails.options);

		setOpenSearchData(false);
	};

	return (
		<List.Item>
			<Flex gap={16}>
				<Image src={imageUrl || IMAGE_FALLBACK_URL} width={60} height={60} className='rounded-md' />
				<Flex justify='space-between' className='cursor-pointer w-full' onClick={handleClick}>
					<Flex gap={3} vertical className='overflow-hidden w-[750px]'>
						<Typography.Title
							className='text-[14px] font-medium m-0'
							ellipsis={{
								tooltip: {
									overlayInnerStyle: { maxHeight: 200, overflow: 'scroll' }
								}
							}}
						>
							{title}
						</Typography.Title>
						{description && (
							<Typography.Paragraph
								className='text-gray-500 text-[13px] m-0 '
								ellipsis={{
									tooltip: {
										overlayInnerStyle: { maxHeight: 200, overflow: 'scroll' }
									}
								}}
							>
								{description}
							</Typography.Paragraph>
						)}
					</Flex>
					<div>
						<Tag color={tagColor} className='text-[9px] py-0 px-1 w-20 text-center'>
							{contentType.charAt(0).toUpperCase() + contentType.slice(1).replace(/-/g, ' ')}
						</Tag>
					</div>
				</Flex>
			</Flex>
		</List.Item>
	);
};

const getHashContentTypeToItemData = (data: IGlobalSearchResponseListItem) => {
	return {
		[EGlobalSearchContentTypes.ARTICLE]: {
			title: data?.title,
			description: data?.description,
			tagColor: 'blue',
			imageUrl: (data as any)?.cardPreview?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.CATEGORY]: {
			title: data?.title,
			description: data?.description,
			tagColor: 'green',
			imageUrl: (data as any)?.image?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.DISTRICT]: {
			title: data?.title,
			description: undefined, // No description for district
			tagColor: 'orange',
			imageUrl: (data as any)?.image?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.MOVIE]: {
			title: data?.title,
			description: data?.summary,
			tagColor: 'volcano',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.SERIES]: {
			title: data?.title,
			description: data?.summary,
			tagColor: 'purple',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.PODCAST]: {
			title: data?.title,
			description: data?.summary,
			tagColor: 'gold',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.STREAM]: {
			title: data?.title,
			description: data?.description,
			tagColor: 'cyan',
			imageUrl: (data as any)?.image?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.POLL]: {
			title: data?.title,
			description: data?.description,
			tagColor: 'geekblue',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.RAFFLE]: {
			title: data?.title,
			description: data?.summary,
			tagColor: 'magenta',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.DYNAMIC_PAGE]: {
			title: data?.title,
			description: data?.summary,
			tagColor: 'brown',
			imageUrl: ''
		},
		[EGlobalSearchContentTypes.PARTY]: {
			title: data?.title,
			description: undefined, // Summary is html text (Rich text)
			tagColor: 'red',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.LEADER]: {
			title: `${data?.firstName} ${data?.lastName}`,
			description: undefined, // No description for leader
			tagColor: 'pink',
			imageUrl: (data as any)?.profileImage?.urls?.thumbnail
		}
	};
};

type TReturnType = { [key in EGlobalSearchContentTypes]: { to: To; options?: NavigateOptions } };
const getHashContentTypeToNavigatePath = (data: IGlobalSearchResponseListItem): TReturnType => {
	return {
		[EGlobalSearchContentTypes.ARTICLE]: { to: `/edit-article/${data?.id}` },
		[EGlobalSearchContentTypes.CATEGORY]: { to: '/categories', options: { state: { searchCategorySlug: data?.slug } } }, // Drawer
		[EGlobalSearchContentTypes.DISTRICT]: { to: `/districts`, options: { state: { searchDistrictId: data?.id } } }, // Drawer
		[EGlobalSearchContentTypes.MOVIE]: { to: `/edit-movie/${data?.id}` },
		[EGlobalSearchContentTypes.SERIES]: { to: `/edit-series/${data?.id}` },
		[EGlobalSearchContentTypes.PODCAST]: { to: '/podcasts', options: { state: { searchPodcastId: data?.id } } }, // Drawer
		[EGlobalSearchContentTypes.STREAM]: { to: '/live-streams', options: { state: { searchLiveStreamId: data?.id } } }, // Drawer
		[EGlobalSearchContentTypes.POLL]: { to: `/polls/edit-poll/${data?.id}` },
		[EGlobalSearchContentTypes.RAFFLE]: { to: '/raffles', options: { state: { searchRaffleId: data?.id } } }, // Drawer
		[EGlobalSearchContentTypes.DYNAMIC_PAGE]: { to: `/edit-dynamic-page/${data?.id}` },
		[EGlobalSearchContentTypes.PARTY]: { to: `/edit-party/${data?.id}` },
		[EGlobalSearchContentTypes.LEADER]: { to: `/edit-leader/${data?.id}` }
	};
};

/* eslint-disable @typescript-eslint/no-use-before-define */
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Image, Input, InputRef, Flex } from 'antd';

import ImageCropperModal from 'components/modals/ImageCropperModal';
import { REGEX } from 'config/constants';
import { HashImageTypeAspectRatio } from 'services/image-helpers.service';
import { EImageType } from 'types/image';

type Props = {
	defaultImage?: string;
	aspectRatio?: `${EImageType}` | 'none';
	buttonProps?: ButtonProps;
	multiple?: boolean;
	onDelete?: () => void;
	onImageSelect?: (image: string | string[] | undefined) => void;
};

const convertFileToBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});
};

const UploadDeviceImageField = ({
	aspectRatio,
	buttonProps,
	multiple,
	defaultImage,
	onImageSelect,
	onDelete
}: Props) => {
	const [base64ImageFiles, setBase64ImageFiles] = useState<string[]>([]); // for multiple images

	const [isCropperModalVisible, setIsCropperModalVisible] = useState(false); // for single image
	const [singleImageFile, setSingleImage] = useState<FileList | null>(null); // for single image
	const [imageCroppedBase64, setImageCroppedBase64] = useState<string | null>(null); // for single image

	const imageInputRef = useRef<InputRef>(null);

	// multiple images & single image
	const handleInputImagesChanged = async (e: ChangeEvent<HTMLInputElement>) => {
		const files = e?.target?.files;

		if (!files) return;

		const validFiles = Array.from(files).filter((file) => REGEX.IMAGE.test(file.name));

		if (validFiles.length === 0) return;

		if (multiple) {
			const base64Files = await Promise.all(validFiles.map((file) => convertFileToBase64(file)));

			setBase64ImageFiles(base64Files);
			onImageSelect?.(base64Files);
		} else {
			setImageCroppedBase64(null);
			setSingleImage(files);
			setIsCropperModalVisible(true);
		}
	};

	// multiple images
	const handleDelete = useCallback(
		(index?: number) => {
			if (multiple && index !== undefined) {
				const newImageFiles = base64ImageFiles.filter((_, i) => i !== index);
				setBase64ImageFiles(newImageFiles);
				onImageSelect?.(newImageFiles.length > 0 ? newImageFiles : undefined);
			} else {
				setBase64ImageFiles([]);
				onImageSelect?.(undefined);
				onDelete?.();
			}
		},
		[base64ImageFiles, multiple, onImageSelect, onDelete]
	);

	// single image
	const handleCropImageModalClosed = useCallback(() => {
		setImageCroppedBase64(null);
		setSingleImage(null);
		setIsCropperModalVisible(false);
	}, []);

	// single image
	const handleImageCroppedSuccessfully = useCallback(
		(data: any) => {
			setImageCroppedBase64(data);
			onImageSelect?.(data);
			setIsCropperModalVisible(false);
		},
		[onImageSelect]
	);

	const singleImage = useMemo(() => {
		return imageCroppedBase64 || defaultImage;
	}, [imageCroppedBase64, defaultImage]);

	return (
		<Flex vertical gap={4}>
			<Flex>
				<Button
					size='large'
					icon={<UploadOutlined />}
					onClick={() => imageInputRef.current?.input?.click?.()}
					{...buttonProps}
				>
					{buttonProps?.title || 'Upload Image'}
				</Button>

				<Input
					ref={imageInputRef}
					type='file'
					multiple={multiple}
					onChange={handleInputImagesChanged}
					size='large'
					className='hidden'
					accept='image/png, image/jpeg, image/jpg, image/tiff, image/webp, image/bmp'
				/>
			</Flex>

			{singleImage &&
				ImagePreview({
					image: singleImage,
					onDelete: () => {
						handleDelete();
						setSingleImage(null);
						setImageCroppedBase64(null);
					}
				})}

			{/* For multiple images */}
			<Flex gap={4} className='overflow-x-scroll pb-1'>
				{base64ImageFiles.map((image, index) => (
					<ImagePreview image={image} onDelete={() => handleDelete(index)} />
				))}
			</Flex>

			<ImageCropperModal
				aspectRatio={HashImageTypeAspectRatio[aspectRatio || 'none']}
				visible={isCropperModalVisible}
				image={singleImageFile}
				onClose={handleCropImageModalClosed}
				onOk={handleImageCroppedSuccessfully}
			/>
		</Flex>
	);
};

export default UploadDeviceImageField;

const ImagePreview = ({ image, onDelete }: { image: string; onDelete?: () => void }) => {
	return (
		<Flex
			gap={4}
			justify='center'
			align='center'
			vertical
			style={{
				marginTop: 4,
				width: 70,
				height: 100,
				padding: 4,
				borderRadius: 8
			}}
		>
			<Image className='rounded-md' width={70} height={70} src={image} />

			<Button
				className='self-center'
				size='small'
				danger
				icon={<DeleteOutlined />}
				style={{ width: 70 }}
				onClick={onDelete}
			/>
		</Flex>
	);
};
